import { Injectable } from '@angular/core';
import { TokenReplacer } from './token-replacer.interface';
import { TokenOption as TokenOptions } from './token-option.interface';

import { BookingEngineToken } from './booking-engine-token.enum';
import { TokenInjectionType } from './token-injection-type.enum';
import { TokenValue } from './token-value.model';
import { QueryArrayFormat } from "@flipto/shared-discovery/enums/query-array-fomat.enum";
import { UrlUtil } from "@flipto/shared-discovery/utils/url.util";

@Injectable({
    providedIn: 'root'
})
export class TokenReplacerService implements TokenReplacer {

    private static supportedTokenKeys: string[] = Object.values(BookingEngineToken);

    constructor() {
    }

    replace(templateUrl: string, suppliedTokens: Array<TokenValue<string | number | Date>>): string {
        let resultUrl = templateUrl;

        // Replace/remove supported tokens first
        for (const tokenKey of TokenReplacerService.supportedTokenKeys) {
            const token = suppliedTokens.find(aToken => aToken.key == tokenKey);
            resultUrl = this.replaceToken(resultUrl, tokenKey, token);
        }

        // Get data for finding extra tokens
        const { queryParams, hashParams } = UrlUtil.parseUrl(resultUrl);
        const extraTokens = suppliedTokens.filter(aToken => !TokenReplacerService.supportedTokenKeys.includes(aToken.key));

        // Add all unknown extra tokens (i.e. extra parameters)
        for (const token of extraTokens) {
            if (token.injectionType == TokenInjectionType.query && !queryParams.has(token.key)) {
                resultUrl = UrlUtil.addToQueryString(resultUrl, token.key, token.value.toString());
            } else if (token.injectionType == TokenInjectionType.fragment && !hashParams.has(token.key)) {
                resultUrl = UrlUtil.addToFragment(resultUrl, token.key, token.value.toString());
            }
        }
        return resultUrl;
    }

    private replaceToken(templateUrl: string, tokenKey: string, token?: TokenValue<string | number | Date>) {
        // target sections that look like:
        //    First Query String Param  - ?key={value,options}
        //    Secondary Param           - &key={value,options}
        //    First Fragment Param      - #key={value,options}
        //    Path Param                - {value,options}
        // Regex explained (First delimiter) (Query key) (query value is token in brackes with optional comma delimited formatters) (trailing delimiter)
        const reQSToken = new RegExp(`(?:([?&#])([^=&#]+=))?({${tokenKey}(?=[,}])[^}]*})([?&#])?`, 'ig');
        // If we find the token, regex replace it
        let didReplace = false;
        templateUrl = templateUrl.replace(reQSToken, (_fullstring: string, startChar: string, queryKey: string, tokenText: string, trailingChar: string) => {
            didReplace = true;
            const options = this.parseOptions(tokenText);
            const tokenValue = token?.value || options.fallback;
            startChar = startChar || '';
            trailingChar = trailingChar || '';
            queryKey = queryKey || '';


            // token value can be array
            if (Array.isArray(tokenValue)) {
                const formattedValue = !!options.format && !!token?.formatter
                    ? token.formatter.format(tokenValue, options.format) as string[]
                    : tokenValue;

                // Delimited strings
                if (options.arrayFormat === QueryArrayFormat.Delimited){
                    return this.buildParamStr(formattedValue.join(options.delimiter), options.encoding, startChar, queryKey, trailingChar);
                }

                // in case BE expect array format like: ?x[]=1&x[]=2
                if (options.arrayFormat === QueryArrayFormat.Brackets) {
                    queryKey += '[]'
                }

                // in case BE expect array format like: ?x=1&x=2
                return formattedValue.reduce((prev, value) => {
                    return prev + this.buildParamStr(value, options.encoding, startChar, queryKey, trailingChar);
                }, '');
            } else {
                // Format if we have a formatter and a requested format
                const formattedValue = !!options.format && !!token?.formatter
                    ? token.formatter.format(tokenValue, options.format) as string
                    : tokenValue?.toString();

                // Encode any values
                if (formattedValue) {
                    return this.buildParamStr(formattedValue, options.encoding, startChar, queryKey, trailingChar);
                }
            }


            // If we're a not the first parameter, drop the start (&), keep the trail (&|#)
            if (startChar == '&') {
                return trailingChar;
            }
            // If we are the only parameter before the hash, drop the start (?) keep the hash
            if (trailingChar == '#') {
                return trailingChar;
            }
            // If we're the first parameter of a query string/hash, keep the first char (?|#), drop the last (&|#)
            return startChar;
        });

        // If we didn't find the token, use injection type to determine where/if we should inject the token key/value
        if (!didReplace && token?.value != null) {
            switch (token.injectionType) {
                case TokenInjectionType.query:
                    return UrlUtil.addToQueryString(templateUrl, tokenKey, token.value.toString());
                case TokenInjectionType.fragment:
                    return UrlUtil.addToFragment(templateUrl, tokenKey, token.value.toString());
            }
        }
        return templateUrl
    }

    private buildParamStr(value: string, encoding: string, startChar: string, queryKey: string, trailingChar: string) {
        if (value) {
            let encodedValue: string;
            switch (encoding) {
                case 'base64':
                    encodedValue = btoa(value)
                    break;
                case 'uri':
                    encodedValue = encodeURIComponent(value);
                    break;
                default:
                    encodedValue = value;
                    break;
            }
            return startChar + queryKey + encodedValue + trailingChar;
        }
    }

    private parseOptions(substring: string): TokenOptions {
        let optionMatch: RegExpExecArray;
        const options = new TokenOptions()
        // New regex every time we call the function to ensure the lastIndex doesn't break re-use
        const optionsRegex = new RegExp(`(?:,)(${Object.keys(options).join('|')})=([^,}]+)`, 'gi')
        // lastIndex ensures that exec will grab subsequent matches (could become matchAll when that is supported)
        while ((optionMatch = optionsRegex.exec(substring)) !== null) {
            options[optionMatch[1]] = optionMatch[2];
        }
        return options;
    }
}


//https://www.reservhotel.com/los-cabos-mexico/nobu-hotel-los-cabos-be/booking-engine/ibe5.main?hotel=10775&aDate=04-Jan-24&dDate=07-Jan-24&airport=NEW&adults=2&child=5&ages=1-onlap&ages=1-onlap&ages=1&ages=4&ages=12&_ga-ft=1bYmev.0.0.0.0.CdOed-DmN-4mR-BqC-vz5niJRO.0.2&_ga=2.134151304.703445052.1703607871-566910502.1703607362
